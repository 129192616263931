import React, {
  createContext,
  Dispatch,
  SetStateAction,
  ReactElement,
  useState,
} from "react";
import { Token } from "../types";

export const SelectionContext = createContext<{
  selectedToken: Token | undefined;
  setSelectedToken: Dispatch<SetStateAction<Token>> | undefined;
  clearSelectedToken: () => void | undefined;
  isTokenSelected: (string, number) => boolean | undefined;
}>({
  selectedToken: undefined,
  setSelectedToken: undefined,
  clearSelectedToken: undefined,
  isTokenSelected: undefined,
});

export const SelectionProvider = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  const [selectedToken, setSelectedToken] = useState<Token | undefined>(
    undefined
  );
  const isTokenSelected = (key: string) => {
    if (!!!selectedToken) return false;
    return selectedToken.contractAddress + selectedToken.tokenId === key;
  };

  return (
    <SelectionContext.Provider
      value={{
        selectedToken,
        setSelectedToken,
        clearSelectedToken: () => {
          setSelectedToken(undefined);
        },
        isTokenSelected,
        // setTokenSelected,
      }}
    >
      <>{children}</>
    </SelectionContext.Provider>
  );
};
