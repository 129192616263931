import React, { useContext, useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import gradientStyle from "../../styles/Animation.module.css";
import { MediaContext } from "../../contexts/MediaContext";
import api from "../../api";
import event from "../../data/event";
import { Col, Row } from "antd";
import { VerificationContext } from "../../contexts/VerificationContext";

const QR = () => {
  const [qrCode, setQrCode] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState(true);
  const [eventInfo, setEventInfo] = useState<typeof event>();
  const { verifiedToken, setVerifiedToken, clearVerifiedToken } =
    useContext(VerificationContext);
  const navigate = useNavigate();
  const mediaSpec = useContext(MediaContext);
  const { isPortrait } = mediaSpec;
  const socket = new api.SocketAPI();

  // GET EVENT INFO FROM PARAMS
  const { eventId } = useParams();

  useEffect(() => {
    if (!!!verifiedToken) {
      // if no verified token, create new room
      socket.createRoom(eventId);
      socket.createRoomListener(onRoomCreated);
      socket.verifiedListener((token) => {
        setVerifiedToken(token);
      });
    } else {
      // if verified token is, navigate to success
      navigate(`/s_/${eventId}/${verifiedToken.contractAddress}/`);
      // navigate(`/s_/${eventId}/${verifiedToken.contractAddress}/${verifiedToken.tokenId}`, {state: {address: verifiedToken.ownerOf}});
    }
  }, [verifiedToken]);

  // callback when room is created
  const onRoomCreated = async (eventRoomId) => {
    // set qr code
    if (!process.env.REACT_APP_BASE_URL) throw new Error("Empty Base Url");
    setQrCode(`${process.env.REACT_APP_BASE_URL}/${eventRoomId}`);
    console.log(`${process.env.REACT_APP_BASE_URL}/${eventRoomId}`);
    // get, set event info
    try {
      const eventInfo = await api.show5.getEventInfo(eventRoomId);
      setEventInfo(eventInfo);
      setLoading(false);
    } catch {
      console.error("Error");
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{
          padding: 30,
          display: "flex",
        }}
      >
        {isPortrait ? (
          <Col>
            <Row justify="center">
              <Code qrCode={qrCode} />
            </Row>
            <Row justify="center">
              <div
                style={{
                  ...styles.wrapper,
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    ...styles.title,
                    fontSize: "6vw",
                    marginBottom: "6vw",
                  }}
                >
                  {!isLoading && eventInfo.name}
                </span>

                <span
                  style={{
                    ...styles.subTitle,
                    fontSize: "3vw",
                  }}
                >
                  Show your
                </span>

                <div>
                  {!isLoading &&
                    eventInfo.collections[0].map((eventCollection, idx) => (
                      <img
                        style={styles.collectionLogo}
                        src={require(`../../assets/images/${eventCollection.openseaSlug}.png`)}
                        key={idx}
                      />
                    ))}
                </div>
              </div>
            </Row>
          </Col>
        ) : (
          <Col>
            <Row gutter={100} align="middle" justify="center">
              <Col>
                <Code qrCode={qrCode} />
              </Col>
              <Col>
                <div
                  style={{
                    ...styles.wrapper,
                    alignItems: "flex-start",
                  }}
                >
                  <span style={{ ...styles.title, fontSize: "6vh" }}>
                    {!isLoading && eventInfo.name}
                  </span>

                  <span style={{ ...styles.subTitle, fontSize: "3vh" }}>
                    Show your
                  </span>

                  <div>
                    {!isLoading &&
                      eventInfo.collections[0].map((eventCollection, idx) => (
                        <img
                          style={styles.collectionLogo}
                          src={require(`../../assets/images/${eventCollection.openseaSlug}.png`)}
                          key={idx}
                        />
                      ))}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        )}
      </motion.div>
      <div
        style={{
          position: "absolute",
          bottom: "0",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <p
          style={{ ...styles.logoTitle, fontSize: isPortrait ? "2vw" : "2vh" }}
        >
          Powered By{" "}
          <img
            style={{ width: isPortrait ? "11vw" : "11vh" }}
            src={require("../../assets/images/show5.jpeg")}
            onClick={() => navigate("/")}
          />
        </p>
      </div>
    </>
  );
};

const Code = ({ qrCode }) => {
  const mediaSpec = useContext(MediaContext);
  const { browser, width: screenX, isPortrait } = mediaSpec;

  return (
    <motion.div
      style={{
        ...styles.qrContainer,
      }}
    >
      <div
        style={{
          width: isPortrait ? "42vw" : "52vh",
          height: isPortrait ? "42vw" : "52vh",
          filter: browser === "chrome" ? "blur(40px)" : "",
          borderRadius: browser === "chrome" ? "" : "15%",
        }}
        className={gradientStyle.gradient}
      />
      <QRCodeSVG
        style={{
          ...styles.qr,
          width: isPortrait ? "35vw" : "45vh",
          height: isPortrait ? "35vw" : "45vh",
        }}
        includeMargin={true}
        value={qrCode}
        // size={200}
      />
    </motion.div>
  );
};

export default QR;

const styles = {
  title: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  } as React.CSSProperties,
  subTitle: {
    color: "white",
    fontWeight: "normal",
    marginBottom: 30,
  } as React.CSSProperties,
  wrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: 50,
  } as React.CSSProperties,
  collectionLogo: {
    width: 80,
    height: 80,
    borderRadius: "50%",
    marginRight: 10,
  } as React.CSSProperties,
  logoTitle: {
    color: "#fff",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    fontSize: "1.1em",
  } as React.CSSProperties,
  logo: {
    width: "5em",
  } as React.CSSProperties,
  qrContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,
  qr: {
    position: "absolute",
    borderRadius: "10%",
  } as React.CSSProperties,
};
