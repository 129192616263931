import React, {
  createContext,
  Dispatch,
  SetStateAction,
  ReactElement,
  useState,
} from "react";
import { VerifiedToken } from "../types";

export const VerificationContext = createContext<{
  verifiedToken: VerifiedToken | undefined;
  setVerifiedToken: Dispatch<SetStateAction<VerifiedToken>> | undefined;
  clearVerifiedToken: () => void | undefined;
}>({
  verifiedToken: undefined,
  setVerifiedToken: undefined,
  clearVerifiedToken: undefined,
});

export const VerificationProvider = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  const [verifiedToken, setVerifiedToken] = useState<VerifiedToken | undefined>(
    undefined
  );

  return (
    <VerificationContext.Provider
      value={{
        verifiedToken,
        setVerifiedToken,
        clearVerifiedToken: () => {
          setVerifiedToken(undefined);
        },
      }}
    >
      <>{children}</>
    </VerificationContext.Provider>
  );
};
