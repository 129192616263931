import React, { useState } from "react";
import api from "../api";
import { Token } from "../types";

export const TokenContext = React.createContext<useTokenProps>(
  undefined as any
);

export const TokenProvider = ({
  chain,
  tokenAddress,
  tokenId,
  userAddress,
  initialData,
  children,
}: {
  chain: string;
  tokenAddress: string;
  tokenId: string;
  userAddress?: string;
  initialData?: Token;
  children: React.ReactElement | React.ReactElement[];
}) => {
  const tokenProps = useToken({
    chain,
    tokenAddress,
    tokenId,
    userAddress,
    initialData,
  });
  return (
    <TokenContext.Provider value={tokenProps}>
      <>{children}</>
    </TokenContext.Provider>
  );
};

// this is data fetching without infinite loading
const useToken = ({
  chain,
  tokenAddress,
  tokenId,
  userAddress,
  initialData,
}: {
  chain: string;
  tokenAddress: string;
  tokenId: string;
  userAddress?: string;
  initialData?: Token;
}) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Token | undefined>(
    initialData ? initialData : undefined
  );

  const refresh = async () => {
    setLoading(true);
    try {
      const token: Token = await api.show5.getToken(tokenAddress, tokenId, {
        chain,
      });
      setData(token);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { error, refresh, loading, data };
};

export type useTokenProps = ReturnType<typeof useToken>;
