import { useState, useEffect, CSSProperties, useContext } from "react";
import { motion } from "framer-motion";

const ProgressiveImg = ({ src, ...props }) => {
  const [imgSrc, setImgSrc] = useState();

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  return imgSrc ? (
    <img
      src={imgSrc}
      alt={props.alt || ""}
      {...props}
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: 20,
      }}
    />
  ) : props.altcomponent ? (
    props.altcomponent
  ) : (
    <motion.div
      initial={{ backgroundColor: "#404040" }}
      animate={{
        backgroundColor: ["#404040", "#303030", "#404040"],
      }}
      transition={{ duration: 2, repeat: Infinity }}
      {...props}
    />
  );
};

export default ProgressiveImg;
