import React, { useState, useContext, createContext } from "react";
import { motion } from "framer-motion";
import { MediaContext } from "../../contexts/MediaContext";
import Button from "../../components/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { FloatingObj } from "../../components";
import { UserAddressContext } from "../../contexts/UserAddress";

import gradientStyle from "../../styles/Animation.module.css";
import Selection from "./Selection";

const Success = () => {
  const mediaSpec = useContext(MediaContext);
  const { isPortrait } = mediaSpec;
  const navigate = useNavigate();
  const [isVerified, setVerified] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [owner, setOwner] = useState("");

  // GET ADDRESS
  const { address } = useContext(UserAddressContext);

  // GET EVENT INFO
  const eventInfo = useLocation().state.eventInfo;

  // GET EVENT ROOM ID
  const { eventRoomId } = useParams();

  // REDIRECT IF NO ADDRESS
  if (!!!address) {
    navigate(`/${eventRoomId}`);
  }

  if (!isVerified) {
    return (
      <Selection
        isLoading={isLoading}
        setImgSrc={setImgSrc}
        setLoading={setLoading}
        setOwner={setOwner}
        setVerified={setVerified}
        title={eventInfo.name}
      />
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: -100,
        }}
      >
        <div
          style={{
            ...styles.title,
            fontSize: isPortrait ? "6vw" : "6vh",
            zIndex: 5,
            paddingTop: "6em",
          }}
        >
          {eventInfo.name}
        </div>
        <div style={styles.gradientWrapper}>
          <div
            style={{
              ...styles.gradient,
              filter: "blur(40px)",
              width: isPortrait ? "75vw" : "75vh",
              height: isPortrait ? "75vw" : "75vh",
            }}
            className={gradientStyle.gradient}
          />
          <motion.img
            src={imgSrc}
            style={{
              ...styles.nft,
              width: isPortrait ? "70vw" : "70vh",
              height: isPortrait ? "70vw" : "70vh",
            }}
            initial={{ filter: "blur(5px)" }}
            animate={{ filter: "blur(0px)" }}
            transition={{ duration: 1.5, delay: 0.5 }}
          />
        </div>
        <h1
          style={{
            ...styles.owner,
            fontSize: isPortrait ? "6vw" : "6vh",
          }}
        >
          Hi, {owner}
        </h1>
      </div>
      <div style={styles.buttonWrapper}>
        <Button title="Try Again" onClick={() => navigate(`/${eventRoomId}`)} />
      </div>
      <FloatingObj route="success" mediaSpec={mediaSpec} />
    </>
  );
};

export default Success;

const styles = {
  container: {
    display: "flex",
    width: "100%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    padding: "0 18px",
    maxWidth: 412,
  } as React.CSSProperties,
  titleWrapper: {
    position: "relative",
    top: "-2%",
    zIndex: 5,
  } as React.CSSProperties,
  title: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  } as React.CSSProperties,
  buttonWrapper: {
    position: "absolute",
    zIndex: 1,
    bottom: "3%",
    display: "flex",
    width: "100%",
    padding: "0 18px",
    justifyContent: "center",
  } as React.CSSProperties,
  nft: {
    borderRadius: "50%",
    position: "absolute",
    objectFit: "cover",
  } as React.CSSProperties,
  owner: {
    color: "white",
    fontWeight: 500,
    zIndex: 5,
    textAlign: "center",
  } as React.CSSProperties,
  gradient: {
    borderRadius: "50%",
  } as React.CSSProperties,
  gradientWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,
};
