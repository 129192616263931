import React from "react";
import { Progress, Row } from "antd";
import { motion } from "framer-motion";

interface TitleProps {
  sec: number;
  owner: string;
  onAnimationEnd: () => void;
  isPortrait: boolean;
  theme?: "Azuki Alley" | "Default";
}

const Title = (props: TitleProps) => {
  const {
    sec,
    owner,
    onAnimationEnd,
    isPortrait,
    theme = "Azuki Alley",
  } = props;

  const [collection, tokenId] = owner.split("#");

  return (
    <>
      <Row
        style={{
          ...styles.container,
          position: theme === "Azuki Alley" ? "absolute" : "static",
          top: isPortrait ? "15%" : "",
          bottom: isPortrait ? "" : "10%",
          left: isPortrait ? "" : "10%",
          display: isPortrait ? "flex" : "",
          alignItems: isPortrait ? "center" : "",
        }}
      >
        <motion.h1
          initial={{
            y: -20,
            opacity: 0,
            zIndex: 5,
          }}
          animate={{
            y: 0,
            opacity: 1,
            zIndex: 5,
          }}
          transition={{ duration: 1.0, delay: 0.5 }}
          style={{
            ...styles.title,
            textAlign: isPortrait ? "center" : "start",
            fontSize: isPortrait ? "8vw" : "8vh",
            marginBottom: isPortrait ? 100 : "",
          }}
        >
          Welcome to The Alley, <br /> {collection}
          {"  "}#{tokenId && tokenId.substr(0, 5)}
        </motion.h1>
      </Row>
      <motion.div
        style={{
          ...styles.timer,
          fontSize: isPortrait ? "3vw" : "3vh",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 2 }}
        onClick={() => onAnimationEnd()}
      >
        Skip
        <div style={{ marginLeft: 10 }}>
          <Progress
            type="circle"
            percent={sec}
            strokeColor="white"
            trailColor="#00000030"
            format={(percent) => percent + ""}
          />
        </div>
      </motion.div>
    </>
  );
};

export default Title;

const styles = {
  container: {
    zIndex: 5,
    width: "100%",
    flexDirection: "column",
  } as React.CSSProperties,
  title: {
    color: "white",
    fontWeight: 800,
    lineHeight: "1.2em",
    textShadow: "#00000030 1px 0 10px",
  } as React.CSSProperties,
  timer: {
    color: "white",
    zIndex: 5,
    position: "absolute",
    bottom: "2%",
    left: "2%",
    textShadow: "#000000 1px 0 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  } as React.CSSProperties,
};
