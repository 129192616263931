import { useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { LayoutContext, useHeader } from "../contexts/LayoutProvider";
import { MediaProvider } from "../contexts/MediaContext";
import AppView from "../views/AppView";
import { UserAddressContext } from "../contexts/UserAddress";
import useWindowSize from "../hooks/useWindowSize";

export default function Root() {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { clearAddress } = useContext(UserAddressContext);
  const headerVisible =
    !location.pathname.includes("/tokens") &&
    !location.pathname.includes("/profile");
  const { width, height } = useWindowSize();
  const ratio = (height / width).toPrecision(3);
  const diagonal = (Math.sqrt(height ** 2 + width ** 2) / 100).toFixed();

  function getDevice(ratio, diagonal) {
    if ((ratio > 1.7 || ratio < 0.6) && diagonal <= 10) {
      return "Mobile";
    } else if (diagonal >= 16) {
      return "Desktop";
    } else {
      return "Tablet";
    }
  }

  const device = getDevice(ratio, diagonal);

  const showDrawer = () => {
    setOpen(true);
  };

  const hideDrawer = () => {
    setOpen(false);
  };

  const navigateToProfile = () => {
    const testAddress = "0xd00B1Ec90e5e7C1dd12eeBc83299c1EeA5116B3f";
    // const testAddress = "0x3605Ca39aC83b8F559B64C453feC6A22AEF99259";

    navigate(`/profile/${testAddress}`);
    setOpen(false);
  };

  const isHostPage =
    location.pathname === "/" || !!location.pathname.match(/\/(e|form)/i);

  const { eventRoomId } = useParams();
  return (
    <AppView.Layout isHolderPage={!isHostPage} isMobile={device === "Mobile"}>
      {!!!location.pathname.match(/e\//i) && (
        <AppView.Header
          isTabletOrMobile={device === "Mobile" || device === "Tablet"}
          onMenuClick={showDrawer}
          visible={headerVisible}
          eventRoomId={eventRoomId}
        />
      )}

      {/* <AppView.HeaderDrawer
        open={open}
        onClose={hideDrawer}
        onProfileClick={navigateToProfile}
        isTabletOrMobile={device === "Mobile" || device === "Tablet"}
        clearAddress={clearAddress}
        hideDrawer={hideDrawer}
      /> */}

      <MediaProvider>
        <Outlet />
      </MediaProvider>
    </AppView.Layout>
  );
}
