import { Token } from "../types";
import axios, { Axios, AxiosRequestConfig } from "axios";

class Show5Api {
  private sAxios: Axios;
  constructor(config?: AxiosRequestConfig) {
    this.sAxios = axios.create(config);
  }

  async getToken(
    contractAddress: string,
    tokenId: string,
    options?: { chain?: string }
  ) {
    const response = await this.sAxios.get(
      `/tokens/${contractAddress}/${tokenId}`,
      { params: { chain: options.chain } }
    );

    return response.data;
  }

  async createEvent(name: string, collections: object, theme: string) {
    const response = await this.sAxios.post("/events", {
      name,
      collections,
      theme,
    });
    return response.data;
  }

  async getEligibleTokens(eventRoomId: string, userAddress: string) {
    const response = await this.sAxios.get(
      `/events/${eventRoomId}/${userAddress}/eligible-tokens`
    );
    return response.data;
  }

  async verifySelectedToken(
    userAddress: string,
    contractAddress: string,
    tokenId: string,
    eventRoomId: string
  ) {
    const response = await this.sAxios.post(
      `/events/verify-token/${userAddress}`,
      { contractAddress, tokenId, eventRoomId }
    );
    return response.data;
  }

  async getVerifiedToken(
    event_id: string,
    contract_address: string,
    token_id: string,
    user_address: string
  ) {
    const response = await this.sAxios.get(
      `/events/${event_id}/verify-token?contract_address=${contract_address}&token_id=${token_id}&user_address=${user_address}`
    );
    return response.data;
  }

  async getEventInfo(eventRoomId: string) {
    const response = await this.sAxios.get(`/events/rooms/${eventRoomId}`);
    return response.data;
  }

  async getHDWallet(code: string) {
    const response = await this.sAxios.get(`/hdwallet/${code}`);
    return response.data;
  }
}

const show5 = new Show5Api({
  baseURL: "https://api-dev.show5.xyz/v1",
  headers: {
    "Content-Type": "application/json",
  },
});

export default show5;
