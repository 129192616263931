import { LeftOutlined } from "@ant-design/icons";
import { Avatar, Image } from "antd";
import React from "react";
import Loading from "../components/Loading";
import ProgressiveImg from "../components/ProgressiveImg";
import Colors from "../constants/Colors";
import { energyUtopiaToken } from "../data/energyUtopia";
import { Token } from "../types";
import { roundFloatingPoint } from "../utils";

// If there is data, 5 states must be defined.
// error, loadinng, empty (!data), data, appending (optional for infinite loading)
export default function TokenView({
  children,
  ...props
}: {
  children: typeof React.Children;
}) {
  let subComponentList = Object.keys(TokenView);
  let subComponents = subComponentList.map((key) => {
    return React.Children.map(children, (child) => child);
  });

  return <>{subComponents.map((component) => component)}</>;
}

TokenView.Container = (props) => {
  const { children } = props;
  return <div style={styles.container}>{children}</div>;
};

TokenView.Header = (props) => {
  const { onBackBtnClick } = props;

  return (
    <div
      style={{ display: "flex", flexDirection: "row", padding: "10px 0px" }}
      onClick={onBackBtnClick}
    >
      <div
        style={{
          fontSize: 15,
          fontWeight: "500",
          color: Colors.yellow,
          paddingTop: 1,
          marginRight: 5,
          marginLeft: 18,
        }}
      >
        <LeftOutlined />
      </div>
      <div
        style={{
          fontSize: 16,
          fontWeight: "500",
          color: Colors.yellow,
        }}
      >
        Back to profile
      </div>
    </div>
  );
};

TokenView.Image = (props) => {
  const { token, loading } = props;

  if (loading || !token)
    return (
      <div style={styles.image}>
        <Loading containerStyle={styles.image} />
      </div>
    );

  return token.animationUrl ? (
    <video src={token.animationUrl} style={styles.image} loop autoPlay muted />
  ) : (
    <ProgressiveImg
      src={token.images.url}
      alt={"Loading..."}
      altcomponent={<Loading containerStyle={styles.image} />}
      style={styles.image}
    />
  );
};

TokenView.Metadata = (props) => {
  const { children } = props;
  return <div style={styles.metadata}>{children}</div>;
};

TokenView.Title = (props: { token: Token; loading: boolean }) => {
  const { token, loading } = props;
  if (loading || !token)
    return (
      <div style={styles.collectionContainer}>
        <div style={styles.title}>{"Loading..."}</div>
        <div style={styles.subtitle}>{"Loading..."}</div>
      </div>
    );
  return (
    <div style={styles.collectionContainer}>
      <div style={styles.title}>
        {token.name
          ? token.name
          : token.collection
          ? token.collection.name + " #" + token.tokenId
          : null}
      </div>
      <div style={styles.subtitle}>
        {token.collection ? token.collection.name : "Unnamed Collection"}
      </div>
    </div>
  );
};

TokenView.Owner = (props) => {
  const { title, subtitle, imageUrl, loading } = props;

  if (loading) return <TokenView.OwnerSkeleton />;

  return (
    <TokenView.OwnerSkeleton
      title={title}
      subtitle={subtitle}
      imageUrl={imageUrl}
    />
  );
};

TokenView.OwnerSkeleton = (props) => {
  const { title, subtitle, imageUrl, loading } = props;

  return (
    <div style={styles.ownerContainer}>
      <div style={styles.sectionTitle}>Owner</div>
      <div style={styles.ownerInfoContainer}>
        <div style={styles.ownerInfo}>
          {imageUrl ? (
            <Avatar src={imageUrl} size={40} />
          ) : (
            <img
              src={require("../assets/images/placeholder.png")}
              width={40}
              height={40}
              alt="Loading..."
            />
          )}
          <div style={{ width: 100, marginLeft: 12 }}>
            <div style={styles.ownerTitle}>{title ? title : ""}</div>
            <div style={{ color: Colors.yellow }}>
              {subtitle ? subtitle : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TokenView.Status = (props) => {
  const { token } = props;
  if (!token) return <TokenView.StatusSkeleton />;

  let lastSalePrice: number | string = "---";
  let tokenSymbol = "";
  let lastSaleTimestamp = "---";
  let network = token.network;
  let tokenStandard = token.contractType;
  if (token.lastSale) {
    tokenSymbol = token.lastSale.paymentToken.symbol;
    lastSaleTimestamp = token.lastSale.timestamp;
    lastSalePrice =
      token.lastSale.price / 10 ** token.lastSale.paymentToken.decimals;
    lastSalePrice = roundFloatingPoint(lastSalePrice, 3);
    lastSalePrice = lastSalePrice + " " + tokenSymbol;
  }

  return (
    <TokenView.StatusSkeleton
      lastSaleTimestamp={lastSaleTimestamp}
      lastSalePrice={lastSalePrice}
      network={network}
      tokenStandard={tokenStandard}
    />
  );
};

TokenView.StatusSkeleton = (props) => {
  const { lastSaleTimestamp, lastSalePrice, network, tokenStandard } = props;

  return (
    <div style={styles.statusContainer}>
      <div style={styles.sectionTitle}>Details</div>
      <div>
        <div style={styles.statusItem}>
          <div style={styles.statusItemTitle}>Last Sold</div>
          <div style={styles.statusItemDescription}>
            {lastSaleTimestamp ? lastSaleTimestamp : "---"}
          </div>
        </div>
        <div style={styles.statusItem}>
          <div style={styles.statusItemTitle}>Last Sold Price</div>
          <div style={styles.statusItemDescription}>
            {lastSalePrice ? lastSalePrice : "---"}
          </div>
        </div>
      </div>
      <div style={styles.statusItem}>
        <div style={styles.statusItemTitle}>Network</div>
        <div style={styles.statusItemDescription}>
          {network ? network : "---"}
        </div>
      </div>
      <div style={styles.statusItem}>
        <div style={styles.statusItemTitle}>Token Standard</div>
        <div style={styles.statusItemDescription}>
          {tokenStandard ? tokenStandard : "---"}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    maxWidth: 412,
    height: "100%",
    maxHeight: 915,
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    overflow: "scroll",
  } as React.CSSProperties,
  image: {
    width: "100%",
    height: "100vw",
    maxHeight: 432,
  },
  collectionContainer: {
    marginTop: 8,
  } as React.CSSProperties,
  metadata: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0px 18px",
  } as React.CSSProperties,
  title: {
    height: 32,
    fontSize: 24,
    fontWeight: "bold",
    lineHeight: 1.5,
  },
  subtitle: {
    height: 20,
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: "600",
    color: Colors.yellow,
  },
  sectionTitle: { fontSize: 12, fontWeight: "600", color: "#888" },
  ownerContainer: {
    width: "100%",
    backgroundColor: "transparent",
    marginTop: 8,
  },
  ownerInfoContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 4,
  } as React.CSSProperties,
  ownerInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  } as React.CSSProperties,
  ownerTitle: {
    fontWeight: "600",
    color: "white",
    fontSize: 16,
  },
  statusContainer: { marginTop: 16 },
  statusItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 4,
  } as React.CSSProperties,
  statusItemTitle: {
    fontWeight: "600",
  },
  statusItemDescription: {
    textAlign: "right",
  } as React.CSSProperties,
};
