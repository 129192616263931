import Lottie from "lottie-react";
import loadingAnimation from "../assets/lottie/loading.json";

const Loading = (props) => {
  const { title = "loading...", containerStyle } = props;
  return (
    <div
      style={{
        ...containerStyle,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        style={{ width: 80, marginBottom: "-10px" }}
        animationData={loadingAnimation}
      />
      <div>{title}</div>
    </div>
  );
};

export default Loading;
