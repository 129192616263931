import { Outlet } from "react-router-dom";

const Layout = () => {
  // if (isTabletOrMobile) {
  //   return (
  //     <div style={styles.container}>
  //       <span>현재 페이지는 데스크탑으로 이용 가능합니다.</span>
  //     </div>
  //   );
  // }
  return <Outlet />;
};

export default Layout;
