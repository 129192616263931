import { ethers } from "ethers";

class EthreumApi {
  private provider: ethers.providers.InfuraProvider;

  constructor() {
    this.provider = new ethers.providers.InfuraProvider("homestead");
  }

  resolve = async (ens: string) => {
    const address = await this.provider.resolveName(ens);
    return address;
  };

  lookup = async (addr: string) => {
    const ens = await this.provider.lookupAddress(addr);
    return ens;
  };

  getBalance = async (address: string) => {
    const result = await this.provider.getBalance(address);
    const balance = Number(ethers.utils.formatEther(result));
    return (Math.round(balance * 100) / 100).toFixed(2);
  };

  getTxCount = async (address: string) => {
    const result = await this.provider.getTransactionCount(address);
    return result;
  };
}

export default new EthreumApi();
