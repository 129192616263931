import React, { createContext, useState } from "react";

export const UserAddressContext = createContext<{
  address: string | undefined;
  setAddress: React.Dispatch<React.SetStateAction<string>> | undefined;
  clearAddress: () => void | undefined;
}>({ address: undefined, setAddress: undefined, clearAddress: undefined });

export const UserAddressProvider = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  const [address, setAddress] = useState<string | undefined>(undefined);

  return (
    <UserAddressContext.Provider
      value={{
        address,
        setAddress,
        clearAddress: () => {
          setAddress(undefined);
        },
      }}
    >
      <>{children}</>
    </UserAddressContext.Provider>
  );
};
