import { useParams } from "react-router-dom";
import { UserInfoContainer } from "../../containers/UserInfo";
import { UserTokenListContainer } from "../../containers/UserTokenList";
import {
  UserProvider,
  UserTokenListProvider,
} from "../../contexts/UserContext";
import ProfileView from "../../views/ProfileView";

export const Profile = (props) => {
  const { address } = useParams();

  return (
    <ProfileView.Container>
      <ProfileView.Header />
      <UserProvider address={address}>
        <UserInfoContainer address={address} />
      </UserProvider>
      <UserTokenListProvider address={address}>
        <UserTokenListContainer address={address} />
      </UserTokenListProvider>
    </ProfileView.Container>
  );
};
