import { CloseOutlined, LeftOutlined, MenuOutlined } from "@ant-design/icons";
import { Avatar, Drawer } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export default function AppView({
  children,
  ...props
}: {
  children: typeof React.Children;
}) {
  let subComponentList = Object.keys(AppView);
  let subComponents = subComponentList.map((key) => {
    return React.Children.map(children, (child) => {
      return child;
    });
  });

  return <>{subComponents.map((component) => component)}</>;
}

AppView.Layout = (props) => {
  const { children, isHolderPage, isMobile } = props;

  return (
    <div
      style={{
        backgroundColor: "black",
        display: "flex",
        flex: 1,
        width: "100%",
        height: isHolderPage && isMobile ? "90vh" : "100vh",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
        flexDirection: "column",
      }}
    >
      {children}
    </div>
  );
};

interface HeaderProps {
  isTabletOrMobile: boolean;
  onMenuClick: () => void;
  visible?: boolean;
  eventRoomId: string;
}

AppView.Header = (props: HeaderProps) => {
  const { isTabletOrMobile, onMenuClick, visible = true, eventRoomId } = props;

  if (!visible) return null;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        position: "absolute",
        top: "0",
        // paddingLeft: "1em",
        // paddingRight: "1.2em",
        // paddingTop: "0.7em",
        // paddingBottom: "0.7em",
        // height: isTabletOrMobile ? "3em" : "4em",
        padding: "6px 10px 6px 5px",
      }}
    >
      <Link to={eventRoomId ? `${eventRoomId}` : "/"}>
        <img
          style={{
            width: isTabletOrMobile ? "6em" : "7.5em",
          }}
          src={require("../assets/images/show5.jpeg")}
          alt=""
        />
      </Link>

      {/* {isTabletOrMobile ? (
        <MenuOutlined
          style={{
            fontSize: "1.2em",
            padding: "0.2em",
            zIndex: 5,
          }}
          onClick={onMenuClick}
        />
      ) : (
        <></>
      )} */}
    </div>
  );
};

interface HeaderDrawerProps {
  open: boolean;
  onClose: () => void;
  isTabletOrMobile: boolean;
  onProfileClick: () => void;
  clearAddress?: () => void;
  hideDrawer?: () => void;
}

AppView.HeaderDrawer = (props: HeaderDrawerProps) => {
  const {
    open,
    onClose,
    isTabletOrMobile,
    onProfileClick,
    clearAddress,
    hideDrawer,
  } = props;

  return (
    <Drawer
      open={open}
      closable={true}
      onClose={onClose}
      bodyStyle={{
        backgroundColor: "#1D1D1D",
      }}
      headerStyle={{ backgroundColor: "#1D1D1D" }}
      closeIcon={
        <CloseOutlined
          style={{
            color: "white",
            fontSize: "1.2em",
          }}
        />
      }
    >
      <div style={{ marginBottom: "2em" }} onClick={onProfileClick}>
        <Avatar
          src={require("../assets/images/placeholder.png")}
          size={isTabletOrMobile ? 60 : 70}
        />
        <span
          style={{
            fontSize: isTabletOrMobile ? "1em" : "1.5em",
            marginLeft: "1em",
          }}
        >
          edward-choi.eth
        </span>
      </div>
      <Link to={"/holder/camera"}>
        <p
          style={{
            fontSize: isTabletOrMobile ? "1em" : "1.5em",
            fontWeight: 600,
            color: "white",
          }}
        >
          Camera
        </p>
      </Link>
      <p
        style={{
          fontSize: isTabletOrMobile ? "1em" : "1.5em",
          fontWeight: 600,
        }}
      >
        Profile
      </p>
      <p style={{ position: "absolute", bottom: "1em" }}>
        <span>Sign out</span> /{" "}
        <span
          onClick={() => {
            clearAddress();
            hideDrawer();
          }}
        >
          Disconnect
        </span>
      </p>
    </Drawer>
  );
};

AppView.Body = (props) => {
  const { children } = props;
  return <div style={styles.body}>{children}</div>;
};

const styles = {
  body: {
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  } as React.CSSProperties,
};
