import React, { useState, useEffect } from "react";
import { Avatar, Button, Drawer } from "antd";
import { MailOutlined, MailTwoTone, UserOutlined } from "@ant-design/icons";
import InputModal from "./InputModal";

const Modal = ({
  handleModalOpen,
  handleModalClose,
  _connectWithWeb,
  _connectWithCode,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Drawer
      open={handleModalOpen}
      closable={false}
      onClose={handleModalClose}
      bodyStyle={styles.modal}
      headerStyle={{ backgroundColor: "#1D1D1D" }}
      placement="bottom"
      height={230}
    >
      <p style={styles.modalTitle}>Enter the Event</p>
      <div
        style={{
          margin: "1.5em",
          display: "flex",
          justifyContent: "space-evenly",
          width: "80%",
        }}
      >
        <div style={styles.wrapper}>
          <Avatar
            size={60}
            style={styles.icon}
            src={require("../assets/images/walletconnect.png")}
            onClick={_connectWithWeb}
          />
          <span style={{ fontSize: "0.8em" }}>WalletConnect</span>
        </div>
        <div style={styles.wrapper}>
          <Avatar
            size={60}
            style={styles.icon}
            shape="square"
            icon={<MailTwoTone twoToneColor="#ef3075" />}
            onClick={() => setOpen(true)}
          />
          <span style={{ fontSize: "0.8em" }}>Invitation Code</span>
        </div>
        <InputModal
          _open={open}
          _onOk={() => setOpen(false)}
          _onCancel={() => setOpen(false)}
          _connectWithCode={_connectWithCode}
        />
      </div>
      {/* <Button
        onClick={handleModalBtnClick}
        style={{
          width: "13em",
          height: "3.5em",
          fontSize: "1.2em",
          fontWeight: 700,
          borderRadius: 8,
          backgroundColor: "#ef3075",
        }}
      >
        Get a Wallet
      </Button>
      <p style={{ position: "absolute", bottom: "2em" }}>
        Don't have a Wallet?{" "}
        <span style={{ color: "#ef3075" }}>Learn More.</span>
      </p> */}
    </Drawer>
  );
};

export default Modal;

const styles = {
  modal: {
    backgroundColor: "#1D1D1D",
    borderTopRightRadius: "2em",
    borderTopLeftRadius: "2em",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as React.CSSProperties,
  modalTitle: {
    fontWeight: 700,
    position: "absolute",
    top: "2em",
    fontSize: "1.1em",
  } as React.CSSProperties,
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as React.CSSProperties,
  icon: {
    margin: 10,
    backgroundColor: "white",
    borderRadius: "20%",
  } as React.CSSProperties,
};
