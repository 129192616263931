import axios, { Axios, AxiosRequestConfig } from "axios";

class RemoveBgApi {
  private rAxios: Axios;
  constructor(config?: AxiosRequestConfig) {
    this.rAxios = axios.create(config);
  }

  async postRemoveBg(formData) {
    const response = await this.rAxios.post(`removebg`, formData);
    return response.data;
  }
}
const removeBgApi = new RemoveBgApi({
  baseURL: "https://api-dev.show5.xyz/v1",
  headers: {
    "X-Api-Key": process.env.REACT_APP_REMOVEBG_API_KEY,
  },
  responseType: "arraybuffer",
});
export default removeBgApi;
