import React, { useState, useEffect, createContext } from "react";
import { useMediaQuery } from "react-responsive";
import useWindowSize from "../hooks/useWindowSize";
import { detect } from "detect-browser";

export const MediaContext = createContext(null);

export const MediaProvider = ({ children }) => {
  const browser = detect();

  const { width, height } = useWindowSize();
  const ratio = (height / width).toPrecision(3);
  const diagonal = (Math.sqrt(height ** 2 + width ** 2) / 100).toFixed();

  function getDevice(ratio, diagonal) {
    if ((ratio > 1.45 || ratio < 0.6) && diagonal <= 10) {
      return "Mobile";
    } else if (diagonal >= 16) {
      return "Desktop";
    } else {
      return "Tablet";
    }
  }

  const device = getDevice(ratio, diagonal);

  return (
    <MediaContext.Provider
      value={{
        isDesktop: device === "Desktop",
        isTablet: device === "Tablet",
        isMobile: device === "Mobile",
        isTabletOrMobile: device === "Mobile" || device === "Tablet",
        isPortrait: height - width > 0,

        browser: browser.name,
        width,
        height,
      }}
    >
      <>{children}</>
    </MediaContext.Provider>
  );
};
