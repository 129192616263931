import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { UserContext } from "../contexts/UserContext";
import ProfileView from "../views/ProfileView";

export const UserInfoContainer = (props) => {
  const navigate = useNavigate();
  const { address } = props;
  const { data, refresh, error, loading } = useContext(UserContext);
  const [balance, setBalance] = useState(0);
  const [txCount, setTxCount] = useState(0);
  const [nftCount, setNftCount] = useState(0);

  const fetchData = async (address: string) => {
    const balance = await api.eth.getBalance(address);
    setBalance(Number(balance));
    const txCount = await api.eth.getTxCount(address);
    setTxCount(txCount);
    const tokenList = await api.gravatar.getTokenList(address, "eth,polygon");
    setNftCount(tokenList.total ? tokenList.total : tokenList.result.length);
  };

  useEffect(() => {
    refresh();
    fetchData(address);
  }, []);

  const _onAvatarClick = () => {
    if (data.contractAddress && data.tokenId)
      navigate(`/tokens/${data.contractAddress}/${data.tokenId}`);
  };

  if (error) return null;
  if (loading) return null;

  return (
    <ProfileView.Profile
      address={address}
      title={data.nickname}
      imageUrl={data.imageUrl}
      balance={balance}
      txCount={txCount}
      nftCount={nftCount}
      onAvatarClick={_onAvatarClick}
      ens={data.ens}
    />
  );
};
