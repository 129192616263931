import { motion } from "framer-motion";

const FloatingObj = ({ route, mediaSpec }) => {
  const { height: screenY, width: screenX, isTabletOrMobile } = mediaSpec;

  if (route === "welcome") {
    return (
      <>
        <motion.img
          src={require("../assets/images/obj1.png")}
          style={{
            width: isTabletOrMobile ? "22%" : "12%",
            position: "absolute",
            x: -(screenX / 2) + 60,
          }}
          initial={{ rotate: -30 }}
          animate={{ rotate: -30, y: [-120, -160, -120] }}
          transition={{
            repeat: Infinity,
            duration: 2,
          }}
        />
        <motion.img
          src={require("../assets/images/obj2.png")}
          style={{
            width: isTabletOrMobile ? "40%" : "25%",
            position: "absolute",
            x: screenX / 2 - 100,
          }}
          initial={{ rotate: 30 }}
          animate={{
            rotate: 30,
            y: [-screenY / 2 + 120, -screenY / 2 + 140, -screenY / 2 + 120],
          }}
          transition={{
            repeat: Infinity,
            duration: 2,
          }}
        />
        <motion.img
          src={require("../assets/images/obj3.png")}
          style={{
            width: isTabletOrMobile ? "20%" : "10%",
            position: "absolute",
            x: screenX / 2 - screenX * 0.2,
          }}
          initial={{ rotate: -0 }}
          animate={{ rotate: -0, y: [60, 40, 60] }}
          transition={{
            repeat: Infinity,
            duration: 2,
          }}
        />
        <motion.img
          src={require("../assets/images/obj4.png")}
          style={{
            width: isTabletOrMobile ? "30%" : "20%",
            position: "absolute",
            x: screenX / 2 - screenX * 0.1,
          }}
          initial={{ rotate: -5 }}
          animate={{
            rotate: -5,
            y: [screenY / 2 - 60, screenY / 2 - 80, screenY / 2 - 60],
          }}
          transition={{
            repeat: Infinity,
            duration: 2,
          }}
        />
        <motion.img
          src={require("../assets/images/obj5.png")}
          style={{
            width: isTabletOrMobile ? "28%" : "20%",
            position: "absolute",
            x: -(screenX / 2) + screenX * 0.2,
          }}
          initial={{ rotate: -0 }}
          animate={{
            rotate: -0,
            y: [screenY / 2 - 100, screenY / 2 - 120, screenY / 2 - 100],
          }}
          transition={{
            repeat: Infinity,
            duration: 2,
          }}
        />
      </>
    );
  }

  return (
    <>
      <motion.img
        src={require("../assets/images/obj1.png")}
        style={{
          width: isTabletOrMobile ? "30%" : "18%",
          position: "absolute",
          x: -(screenX / 2) + 60,
        }}
        initial={{
          rotate: -30,
          y: -120,
          opacity: 1,
          filter: "blur(0px)",
        }}
        animate={{ filter: "blur(2px)" }}
        transition={{ duration: 1.1, delay: 0.5 }}
      />
      <motion.img
        src={require("../assets/images/obj2.png")}
        style={{
          width: isTabletOrMobile ? "35%" : "25%",
          position: "absolute",
          x: screenX / 2 - 100,
          y: -screenY / 2 + 120,
        }}
        initial={{ rotate: 30, opacity: 1, filter: "blur(0px)" }}
        animate={{ filter: "blur(1px)" }}
        transition={{ duration: 1.1, delay: 0.5 }}
      />
      <motion.img
        src={require("../assets/images/obj3.png")}
        style={{
          width: isTabletOrMobile ? "30%" : "11%",
          position: "absolute",
          x: screenX / 2 - screenX * 0.2,
        }}
        initial={{
          rotate: -0,
          y: 60,
          opacity: 1,
          filter: "blur(0px)",
        }}
        animate={{ filter: "blur(1.5px)" }}
        transition={{ duration: 1.1, delay: 0.5 }}
      />
      <motion.img
        src={require("../assets/images/obj4.png")}
        style={{
          width: isTabletOrMobile ? "20%" : "15%",
          position: "absolute",
          x: screenX / 2 - screenX * 0.1,
        }}
        initial={{
          rotate: -5,
          y: screenY / 2 - 20,
          opacity: 1,
          filter: "blur(0px)",
        }}
        animate={{ filter: "blur(1.5px)" }}
        transition={{ duration: 1.1, delay: 0.5 }}
      />
      <motion.img
        src={require("../assets/images/obj5.png")}
        style={{
          width: isTabletOrMobile ? "40%" : "25%",
          position: "absolute",
          x: -(screenX / 2) + screenX * 0.2,
        }}
        initial={{
          rotate: -0,
          y: screenY / 2 - 100,
          opacity: 1,
          filter: "blur(0px)",
        }}
        animate={{ filter: "blur(3px)" }}
        transition={{ duration: 1.1, delay: 0.5 }}
      />
    </>
  );
};

export default FloatingObj;
