import { useEffect, useContext, useState } from "react";
import { VerificationContext } from "../../contexts/VerificationContext";
import Azuki from "./Azuki";
import { useNavigate, useParams } from "react-router-dom";

export default function Success() {
  const { verifiedToken, setVerifiedToken, clearVerifiedToken } =
    useContext(VerificationContext);
  const navigate = useNavigate();
  const [isAnimating, setAnimating] = useState(!!verifiedToken);

  // GET EVENT ID FROM PARAMS
  const { eventId } = useParams();

  useEffect(() => {
    isAnimating && setVerifiedToken(null);
  }, [verifiedToken]);

  const onAnimationEnd = () => {
    clearVerifiedToken();
    navigate(`/e/${eventId}`);
  };

  return (
    <Azuki verifiedToken={verifiedToken} onAnimationEnd={onAnimationEnd} />
  );
}
