const tintColorLight = "#2f95dc";
const tintColorDark = "#fff";
// const tintColorDark = '#FFB400';
export const primary = "#888";
export const purple = "#B443F7";
export const blue = "#1890FF";
export const secondary = "#8d8d8d";
export const green = "#52c41a";
export const red = "#CA3C4B";
export const nooxPurple = "#7754F7";
export const yellow = "#f6b21b";
export const gray = "#888888";
export const darkgray = "#1D1C21";

const Colors = {
  light: {
    text: "#000",
    background: "#fff",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
  },
  purple,
  blue,
  secondary,
  green,
  red,
  nooxPurple,
  yellow,
  gray,
  darkgray,
};

export default Colors;
