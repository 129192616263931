import io from "socket.io-client";

class SocketAPI {
  private socket;

  constructor() {
    this.socket = io("https://api-dev.show5.xyz", {
      path: "/rooms",
      autoConnect: false,
    });
  }

  public getConnection = () => {
    this.socket.on("connection", () => {
      console.log(`getConnection socketId: ${this.socket.id}`);
    });
  };

  public createRoom = (eventId: string) => {
    this.socket.emit("createRoom", {
      eventId,
    });
    this.socket.connect();
    this.getConnection();
  };

  public createRoomListener = (onRoomCreated) => {
    this.socket.on("roomCreated", (data) => {
      console.log("roomCreated!");
      onRoomCreated(data.eventRoomId);
    });
  };

  public verifiedListener = (setVerifiedToken): any => {
    this.socket.on("verified", (data) => {
      setVerifiedToken(data);
    });
  };
}

export default SocketAPI;
