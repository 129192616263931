export default {
  azuki: {
    id: 1,
    slug: "azuki",
    lottie: "azuki_white",
    nfts: ["azuki1", "azuki2", "azuki3"],
    bgColors: ["#45436f", "#eff3f2", "#c62f40"],
    colors: ["#fff", "#000", "#fff"],
    contractAddress: "0xED5AF388653567Af2F388E6224dC7C4b3241C544",
  },
  beanzofficial: {
    id: 2,
    slug: "beanzofficial",
    lottie: "pop",
    nfts: ["beanzofficial1", "beanzofficial2", "beanzofficial3"],
    bgColors: ["#45436f", "#eff3f2", "#c62f40"],
    colors: ["#fff", "#000", "#fff"],
    contractAddress: "0x306b1ea3ecdf94aB739F1910bbda052Ed4A9f949",
  },
  "doodles-official": {
    id: 3,
    slug: "doodles-official",
    lottie: "pop",
    nfts: ["azuki1", "azuki2", "azuki3"],
    bgColors: ["#45436f", "#eff3f2", "#c62f40"],
    colors: ["#fff", "#000", "#fff"],
    contractAddress: "0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e",
  },
  "the-dooplicator": {
    id: 4,
    slug: "the-dooplicator",
    lottie: "pop",
    nfts: ["azuki1", "azuki2", "azuki3"],
    bgColors: ["#45436f", "#eff3f2", "#c62f40"],
    colors: ["#fff", "#000", "#fff"],
    contractAddress: "0x466CFcD0525189b573E794F554b8A751279213Ac",
  },
  clonex: {
    id: 5,
    slug: "clonex",
    lottie: "pop",
    nfts: ["azuki1", "azuki2", "azuki3"],
    bgColors: ["#45436f", "#eff3f2", "#c62f40"],
    colors: ["#fff", "#000", "#fff"],
    contractAddress: "0x49cF6f5d44E70224e2E23fDcdd2C053F30aDA28B",
  },
};
