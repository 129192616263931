import { useContext, useEffect } from "react";
import { UserContext } from "../contexts/UserContext";
import TokenView from "../views/TokenView";

export const TokenOwnerContainer = (props) => {
  const { data, error, loading, refresh } = useContext(UserContext);

  useEffect(() => {
    refresh();
  }, []);

  if (error) return null;

  return (
    <TokenView.Owner
      title={data.nickname}
      subtitle={data.ens ? data.ens : data.shortenedAddress}
      imageUrl={data.imageUrl}
      loading={loading}
    />
  );
};
