import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { MediaContext } from "../../contexts/MediaContext";
import { CodeContext } from "../../contexts/CodeContext";
import Button from "../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import Web3Modal from "../../libs/Web3Modal";
import { UserAddressContext } from "../../contexts/UserAddress";
import api from "../../api";
import event from "../../data/event";
import { FloatingObj } from "../../components";
import Modal from "../../components/Modal";
import Loading from "../../components/Loading";

const Welcome = () => {
  const mediaSpec = useContext(MediaContext);

  const { isPortrait } = mediaSpec;
  const [isLoading, setLoading] = useState(true);
  const [eventInfo, setEventInfo] = useState<typeof event>();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  // GET ADDRESS
  const { address, setAddress, clearAddress } = useContext(UserAddressContext);

  // GET EVENT ROOM ID FROM PARAMS
  const { eventRoomId } = useParams();
  const navigate = useNavigate();

  // GET EVENT INFO
  useEffect(() => {
    getEventInfo(eventRoomId);
  }, []);

  useEffect(() => {
    console.log("address ::: ", address);
  }, [address]);

  const getEventInfo = async (eventRoomId) => {
    try {
      const eventInfo = await api.show5.getEventInfo(eventRoomId);
      setEventInfo(eventInfo);
      setLoading(false);
    } catch {
      console.error("Error");
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const _connectWithWeb = async () => {
    // SET ADDRESS
    await Web3Modal.connect();
    const currentAddress = await Web3Modal.getAccount();
    setAddress(currentAddress);
    getEligibleTokens(currentAddress);
  };

  const _connectWithCode = async (code) => {
    try {
      const currentAddress = await api.show5.getHDWallet(code);
      setAddress(currentAddress);
      getEligibleTokens(currentAddress);
    } catch (error: any) {
      setError(error);
    }
  };

  const getEligibleTokens = async (address) => {
    try {
      setLoading(true);
      let eligibleTokens = await api.show5.getEligibleTokens(
        eventRoomId,
        address
      );
      eligibleTokens = eligibleTokens.filter((el) => !!el.imageUrls.imageUrl);
      navigate(`/s/${eventRoomId}`, {
        state: { eligibleTokens, eventInfo, eventRoomId },
      });
    } catch {
      console.error("Error");
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Loading
        containerStyle={{
          ...styles.tokenListContainer,
          paddingTop: 100,
        }}
      />
    );
  }

  return (
    <motion.div
      style={styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <span style={{ ...styles.title, fontSize: isPortrait ? "8vw" : "8vh" }}>
        {!isLoading && eventInfo.name}
      </span>

      <div style={styles.wrapper}>
        <span
          style={{
            ...styles.subTitle,
            fontSize: isPortrait ? "4.5vw" : "4.5vh",
          }}
        >
          Show your
        </span>
        <div style={{ marginLeft: 10 }}>
          {!isLoading &&
            eventInfo.collections[0].map((eventCollection, idx) => (
              <img
                style={{
                  ...styles.icon,
                  width: isPortrait ? "13vw" : "13vh",
                  height: isPortrait ? "13vw" : "13vh",
                }}
                src={require(`../../assets/images/${eventCollection.openseaSlug}.png`)}
                key={idx}
              />
            ))}
        </div>
      </div>
      <div style={styles.buttonWrapper}>
        <Button onClick={() => showDrawer()} title="Enter the Event" />
      </div>
      <CodeContext.Provider value={error}>
        <Modal
          handleModalOpen={open}
          _connectWithWeb={_connectWithWeb}
          handleModalClose={() => setOpen(false)}
          _connectWithCode={_connectWithCode}
        />
      </CodeContext.Provider>
      <FloatingObj route={"welcome"} mediaSpec={mediaSpec} />
    </motion.div>
  );
};

export default Welcome;

const styles = {
  container: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "1em",
  } as React.CSSProperties,
  title: {
    color: "white",
    fontWeight: "bold",
    // zIndex: 5,
  } as React.CSSProperties,
  subTitle: {
    color: "white",
    fontWeight: "normal",
    marginBottom: 30,
    textAlign: "center",
  } as React.CSSProperties,
  wrapper: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
  } as React.CSSProperties,
  icon: {
    borderRadius: "50%",
    marginRight: 10,
  } as React.CSSProperties,
  button: {
    padding: "1.3em 1.5em",
    fontSize: "1.2em",
    fontWeight: 700,
    borderRadius: 8,
    marginTop: "30%",
    zIndex: 5,
  } as React.CSSProperties,
  buttonWrapper: {
    position: "absolute",
    width: "100%",
    bottom: "3%",
    padding: "0 18px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  } as React.CSSProperties,
  tokenListContainer: {
    width: "100%",
    maxHeight: 675,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "stretch",
    overflowY: "scroll",
    marginBottom: 10,
    flex: 1,
    flexDirection: "column",
  } as React.CSSProperties,
};
