import { ethers } from "ethers";
import web3modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

if (!process.env.REACT_APP_INFURA_API_KEY) throw new Error('Empty infura api key');

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: process.env.REACT_APP_INFURA_API_KEY, // required
    },
  },
};

class Web3Modal {
  public web3: any;
  public provider: any;

  constructor() {
    this.web3 = new web3modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions, // required
    });
  }

  async connect() {
    try {
      const instance = await this.web3.connect();
      this.provider = new ethers.providers.Web3Provider(instance);
    } catch (e) {
      throw e;
    }
  }

  async getAccount() {
    const accounts = await this.provider.listAccounts();
    if (!accounts) return null;
    return accounts[0];
  }
}

const Web3Instance = new Web3Modal();

export default Web3Instance;
