import React, { useState, useEffect, useContext } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import {
  HostSuccess,
  HostWelcome,
  QR,
  HolderSuccess,
  Camera,
  Form,
  Permission,
  Loading,
  HolderWelcome,
  HolderLayout,
  HostLayout,
} from "./screens";

import "./styles/App.less";
import { Profile } from "./screens/holder/Profile";
import { TokenDetail } from "./screens/holder/TokenDetail";
import NoMatch from "./screens/NoMatch";
import Root from "./screens/Root";
import {
  UserAddressContext,
  UserAddressProvider,
} from "./contexts/UserAddress";
import { LayoutProvider, useHeader } from "./contexts/LayoutProvider";
import { VerificationProvider } from "./contexts/VerificationContext";
import { SelectionProvider } from "./contexts/SelectionContext";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route element={<HostLayout />}>
        <Route index element={<HostWelcome />} />
        <Route path="e/:eventId" element={<QR />} />
        <Route path="s_/:eventId/:tokenAddress" element={<HostSuccess />} />
        {/* <Route
          path="s_/:eventId/:tokenAddress/:tokenId"
          element={<HostSuccess />}
        /> */}
        {/* <Route path="s_/:eventId" element={<HostSuccess />} /> */}
        <Route path="form" element={<Form />} />
      </Route>

      <Route element={<HolderLayout />}>
        <Route index element={<HolderWelcome />} />
        <Route path=":eventRoomId" element={<HolderWelcome />} />
        <Route path="s/:eventRoomId" element={<HolderSuccess />} />
        <Route path="camera" element={<Camera />} />
        {/* <Route path="permission" element={<Permission />} /> */}
        <Route path="profile/:address" element={<Profile />} />
        <Route
          path="tokendetail/:tokenAddress/:tokenId"
          element={<TokenDetail />}
        />
      </Route>

      <Route path="profile/:address" element={<Profile />} />
      <Route
        path="tokens/:chain/:tokenAddress/:tokenId/:userAddress"
        element={<TokenDetail />}
      />
      <Route path="loading" element={<Loading />} />
      <Route path="*" element={<NoMatch />} />
    </Route>
  )
);

export default function App() {
  return (
    <UserAddressProvider>
      <VerificationProvider>
        <SelectionProvider>
          <RouterProvider router={router} fallbackElement={<NoMatch />} />
        </SelectionProvider>
      </VerificationProvider>
    </UserAddressProvider>
  );
}
