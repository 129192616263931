import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { UserTokenListProvider } from "../../contexts/UserContext";
import { UserTokenListContainer } from "../../containers/UserTokenList";
import { message } from "antd";
import api from "../../api";
import { useLocation, useParams } from "react-router-dom";
import { UserAddressContext } from "../../contexts/UserAddress";
import { Token, VerifiedToken } from "../../types";
import Button from "../../components/Button";
import ProfileView from "../../views/ProfileView";
import { SelectionContext } from "../../contexts/SelectionContext";

interface SelectionProps {
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setImgSrc: Dispatch<SetStateAction<string>>;
  setOwner: Dispatch<SetStateAction<string>>;
  setVerified: Dispatch<SetStateAction<boolean>>;
  title: string;
}

const Selection = (props: SelectionProps) => {
  const { isLoading, setLoading, setImgSrc, setOwner, setVerified, title } =
    props;
  const { address } = useContext(UserAddressContext);

  // GET EVENT ROOM ID
  const { eventRoomId } = useParams();

  const { selectedToken, setSelectedToken, isTokenSelected } =
    useContext(SelectionContext);

  const handleTokenClick = (token) => {
    if (!!selectedToken && selectedToken.tokenId === token.tokenId) {
      setSelectedToken(null);
    } else {
      setSelectedToken(token);
    }
    console.log("selectedToken :::", selectedToken);
  };

  // VERIFY SELECTED TOKEN
  const handleButtonClick = async () => {
    // THROW ERROR IF NO TOKEN SELECTED
    if (!!!selectedToken) {
      message.error("NFT를 선택해 주세요");
      return;
    }
    try {
      setLoading(true);

      const verifiedToken: VerifiedToken =
        (await api.show5.verifySelectedToken(
          address,
          selectedToken.contractAddress,
          selectedToken.tokenId,
          eventRoomId
        )) || selectedToken;

      const { imageUrls, name } = verifiedToken;
      setImgSrc(imageUrls.imageUrl + "=s2048");
      setOwner(name);
      setVerified(true);
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ProfileView.Container>
      <div style={{ height: "2em" }} />
      <ProfileView.Header title={title} center />
      <UserTokenListProvider address={address}>
        <UserTokenListContainer
          address={address}
          isHolderSelection={true}
          onClick={handleTokenClick}
          isTokenSelected={isTokenSelected}
        />
      </UserTokenListProvider>
      <div style={{ marginTop: 10 }}>
        <Button
          onClick={handleButtonClick}
          title={isLoading ? "Loading..." : "Select"}
        />
      </div>
    </ProfileView.Container>
  );
};
export default Selection;

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "70%",
  } as React.CSSProperties,
  buttonWrapper: {
    position: "absolute",
    zIndex: 1,
    bottom: "3%",
    display: "flex",
    width: "100%",
    padding: "0 18px",
    justifyContent: "center",
  } as React.CSSProperties,
};
