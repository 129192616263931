import React from "react";
import { TokenDetailContainer } from "../../containers/TokenDetail";
import { TokenProvider } from "../../contexts/TokenContext";
import { useParams } from "react-router-dom";

export const TokenDetail = () => {
  const { chain, tokenAddress, tokenId, userAddress } = useParams();

  return (
    <TokenProvider tokenAddress={tokenAddress} tokenId={tokenId} chain={chain}>
      <TokenDetailContainer userAddress={userAddress} />
    </TokenProvider>
  );
};
