import React, { useContext } from "react";
import { motion } from "framer-motion";
import { MediaContext } from "../../contexts/MediaContext";
import { useNavigate } from "react-router-dom";
import collections from "../../data/collection";
import { Button, FloatingObj } from "../../components";
import { Col, Row } from "antd";

const Welcome = () => {
  const mediaSpec = useContext(MediaContext);
  const { isPortrait } = mediaSpec;
  const navigate = useNavigate();

  return (
    <>
      <Row>
        <Col xs={24}>
          <motion.div
            style={styles.container}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <span
              style={{
                ...styles.mainTitle,
                fontSize: isPortrait ? "8vw" : "8vh",
              }}
            >
              THE NFT EXPRIENCE
            </span>
            <span
              style={{
                ...styles.subTitle,
                fontSize: isPortrait ? "6vw" : "6vh",
              }}
            >
              like never before
            </span>
            <div style={styles.wrapper}>
              {Object.keys(collections).map((collection, idx) => (
                <img
                  style={{
                    ...styles.collection,
                    width: isPortrait ? "13vw" : "13vh",
                  }}
                  key={idx}
                  src={require(`../../assets/images/${collection}.png`)}
                />
              ))}
            </div>
            <div style={styles.buttonWrapper}>
              <Button
                onClick={() => {
                  navigate("/form");
                }}
                title="Create Meetup"
              />
            </div>
          </motion.div>
        </Col>
      </Row>
      <FloatingObj route="welcome" mediaSpec={mediaSpec} />
    </>
  );
};

export default Welcome;

// STYLES
const styles = {
  container: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: 100,
  } as React.CSSProperties,
  mainTitle: {
    fontSize: "8vw",
    fontWeight: "bold",
    color: "#fff",
    textAlign: "center",
  } as React.CSSProperties,
  subTitle: {
    fontSize: "6vw",
    color: "#fff",
    fontWeight: 200,
    textAlign: "center",
  } as React.CSSProperties,
  wrapper: {
    marginTop: 40,
    display: "flex",
    justifyContent: "center",
  } as React.CSSProperties,
  collection: {
    width: "13vw",
    borderRadius: "50%",
    margin: 10,
  } as React.CSSProperties,
  buttonWrapper: {
    display: "flex",
    marginTop: "10%",
    justifyContent: "center",
    width: "100%",
  } as React.CSSProperties,
};
