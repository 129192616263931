import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import AppView from "../../views/AppView";
import { MediaContext } from "../../contexts/MediaContext";
import { detect } from "detect-browser";

const Layout = () => {
  const mediaSpec = useContext(MediaContext);
  const browser = detect();

  const { isTabletOrMobile } = mediaSpec;

  if (!isTabletOrMobile) {
    return (
      <div style={styles.container}>
        <span>현재 페이지는 모바일로 이용 가능합니다.</span>
      </div>
    );
  }
  return (
    <AppView.Body browser={browser.name}>
      <Outlet />
    </AppView.Body>
  );
};

export default Layout;

const styles = {
  container: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,
};
