import { useCallback, useContext, useEffect, useRef } from "react";
import { UserTokenListContext } from "../contexts/UserContext";
import ProfileView from "../views/ProfileView";
import { useNavigate } from "react-router-dom";

export const UserTokenListContainer = (props) => {
  const { address, isHolderSelection, onClick, isTokenSelected } = props;
  const { data, refresh, next, error, refreshing, loading, hasMore } =
    useContext(UserTokenListContext);

  const navigate = useNavigate();

  let observer: { current: IntersectionObserver } = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (data.length >= 20 && entries[0].isIntersecting && hasMore) {
          next();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    next();
  }, []);

  const _onClick = isHolderSelection
    ? onClick
    : (chain: string, contractAddress: string, tokenId: string) => {
        navigate(`/tokens/${chain}/${contractAddress}/${tokenId}/${address}`);
      };

  if (error) return null;
  if (data.length === 0) return <ProfileView.TokenListLoading />;

  return (
    <ProfileView.TokenList
      data={data}
      innerRef={lastItemRef}
      loading={loading}
      hasMore={hasMore}
      refresh={refresh}
      onClick={_onClick}
      isHolderSelection={isHolderSelection}
      isTokenSelected={isTokenSelected}
    />
  );
};
