import axios, { Axios, AxiosRequestConfig } from "axios";
import { ListResponse, Token } from "../types";

if (!process.env.REACT_APP_HOPAE_BASIC_AUTH)
  throw new Error("Hopae Basic Auth key doesn't exist!");
if (!process.env.REACT_APP_GRAVATAR_API_KEY)
  throw new Error("Web3 Gravatar api key doesn't exist!");

console.log(process.env);

class GravatarApi {
  private gAxios: Axios;
  constructor(config?: AxiosRequestConfig) {
    this.gAxios = axios.create(config);
  }

  async getGravatar(address: string) {
    const response = await this.gAxios.get(
      `avatars/${address}?includes_metadata=true`
    );
    return response.data;
  }

  async getTokenList(
    address: string,
    chains?: string,
    cursor?: string
  ): Promise<ListResponse<Token>> {
    const response = await this.gAxios.get(`avatars/${address}/list`, {
      params: { chains, cursor },
    });
    return response.data;
  }

  async updateGravatar(
    address: string,
    contractAddress: string,
    tokenId: string,
    imageUrl: string
  ) {
    const response = await this.gAxios.put(`avatars/${address}`, {
      contractAddress,
      tokenId,
      imageUrl,
    });
    return response.data;
  }
}

const gravatarApi = new GravatarApi({
  baseURL: "https://dev-avatar-api.hopae.xyz/v1",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Basic ${process.env.REACT_APP_HOPAE_BASIC_AUTH}`,
    "Api-Key": process.env.REACT_APP_GRAVATAR_API_KEY,
  },
});

export default gravatarApi;
