import React, { useState, useEffect, useContext } from "react";
import { QrReader } from "react-qr-reader";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import { UserAddressContext } from "../../contexts/UserAddress";

const Camera = (props) => {
  const [scanned, setScanned] = useState("");
  const navigate = useNavigate();
  const { address } = useContext(UserAddressContext);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {!!!scanned && (
        <QrReader
          onResult={async (result, error) => {
            if (scanned) return;
            if (!!result) {
              const eventRoomId = result.getText().replace("qr::", "");
              try {
                const eligibleTokens = await api.show5.getEligibleTokens(
                  eventRoomId,
                  address
                );
                navigate("/holder/verification", { state: eligibleTokens });
              } catch (error: any) {
              } finally {
                setScanned(result.getText());
                return;
              }
            }
            if (!!error) {
              // console.info(error);
            }
          }}
          constraints={{
            facingMode: "environment",
          }}
        />
      )}
    </div>
  );
};

export default Camera;
