import React, { useState, useEffect, useContext } from "react";
import { Button } from "antd";
import { MediaContext } from "../contexts/MediaContext";

type ButtonType = {
  title: string;
  onClick?: () => void;
  disabled?: boolean;
  htmlType?: "submit";
};

const ButtonComponent = (props: ButtonType) => {
  const { onClick, title, disabled, htmlType } = props;
  const mediaSpec = useContext(MediaContext);
  const { isPortrait } = mediaSpec;

  return (
    <Button
      type="primary"
      style={{
        padding: "1.7em 0em",
        fontSize: "1.2em",
        fontWeight: 700,
        width: "100%",
        borderRadius: 8,
        zIndex: 5,
        maxWidth: 412,
      }}
      onClick={onClick}
      disabled={disabled}
      htmlType={htmlType}
    >
      {title}
    </Button>
  );
};

export default ButtonComponent;
