import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TokenContext } from "../contexts/TokenContext";
import { UserProvider } from "../contexts/UserContext";
import TokenView from "../views/TokenView";
import { TokenOwnerContainer } from "./TokenOwner";

export const TokenDetailContainer = (props) => {
  const { userAddress } = props;
  const { data, refresh, error, loading } = useContext(TokenContext);
  const navigate = useNavigate();

  useEffect(() => {
    refresh();
  }, []);

  if (error) return null;

  return (
    <TokenView.Container>
      <TokenView.Header
        onBackBtnClick={() => navigate(`/profile/${userAddress}`)}
      />
      <TokenView.Image token={data} loading={loading} />
      <TokenView.Metadata>
        <TokenView.Title token={data} loading={loading} />
        <UserProvider address={userAddress}>
          <TokenOwnerContainer userAddress={userAddress} />
        </UserProvider>
        <TokenView.Status token={data} loading={loading} />
      </TokenView.Metadata>
    </TokenView.Container>
  );
};
