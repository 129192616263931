import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import { CodeContext } from "../contexts/CodeContext";

const InputModal = ({ _open, _onCancel, _onOk, _connectWithCode }) => {
  const error = useContext(CodeContext);

  useEffect(() => {
    if (error) {
      message.error("유효한 코드가 아닙니다");
    }
  }, [error]);

  const _handleOK = async (values) => {
    const { code } = values;
    _connectWithCode(code);
  };

  return (
    <Modal open={_open} footer={null} centered>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={(values) => {
          _handleOK(values);
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        <Form.Item
          name="code"
          label="Invitation Code"
          rules={[{ required: true, message: "초대 코드를 작성해 주세요." }]}
        >
          <Input style={{ backgroundColor: "#ef3075" }} />
        </Form.Item>
        <Form.Item
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            htmlType="button"
            type="text"
            onClick={_onCancel}
            style={{
              color: "#eee",
              height: 30,
            }}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            name="submit"
            type="text"
            style={{
              color: "#ef3075",
              height: 30,
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InputModal;
