import React, { useState, useEffect, useContext } from "react";
import { Form as Formbox, Input, Avatar, Space, Radio } from "antd";
import collections from "../../data/collection";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import api from "../../api";
import Button from "../../components/Button";
import { Col, Row } from "antd";
import { MediaContext } from "../../contexts/MediaContext";

const Form = () => {
  const mediaSpec = useContext(MediaContext);
  const { isMobile, isPortrait } = mediaSpec;
  const [selectedCollections, setSelectedCollections] = useState([]);
  const navigate = useNavigate();

  // REDIRECT IF MOBILE
  if (isMobile) {
    return (
      <div style={styles.container}>
        <span>현재 페이지는 데스크탑으로 이용 가능합니다.</span>
      </div>
    );
  }

  const isCollectionSelected = (collection: string) => {
    return selectedCollections.includes(collection);
  };

  // CREATE EVENT
  const onFinish = async (values: any) => {
    const payload = {
      name: values.name,
      theme: values.theme,
      collections: [
        selectedCollections.map((e) => {
          return {
            openseaSlug: collections[e].slug,
            contractAddress: collections[e].contractAddress,
          };
        }),
      ],
    };

    const event = await api.show5.createEvent(
      payload.name,
      payload.collections,
      payload.theme
    );
    navigate(`/e/${event["_id"]}`);
  };

  return (
    <Row justify="center" align="middle" style={{ margin: 30 }}>
      <Col xs={24}>
        <h1 style={{ ...styles.title, fontSize: isPortrait ? "6vw" : "6vh" }}>
          ABOUT YOUR EVENT
        </h1>

        <Formbox style={styles.form} onFinish={onFinish}>
          <div>
            <span
              style={{
                ...styles.label,
                fontSize: isPortrait ? "2vw" : "2vh",
              }}
            >
              Event Name:{" "}
            </span>
            <Formbox.Item name="name">
              <Input />
            </Formbox.Item>
          </div>
          <div>
            <span
              style={{ ...styles.label, fontSize: isPortrait ? "2vw" : "2vh" }}
            >
              Theme:{" "}
            </span>
            <Formbox.Item name="theme">
              <Radio.Group>
                <Radio
                  value="Azuki Alley"
                  style={{ fontSize: isPortrait ? "2vw" : "2vh" }}
                >
                  Azuki Alley
                </Radio>
                <Radio
                  value="Default"
                  disabled
                  style={{ fontSize: isPortrait ? "2vw" : "2vh" }}
                >
                  Default
                </Radio>
              </Radio.Group>
            </Formbox.Item>
          </div>
          <div>
            <span
              style={{ ...styles.label, fontSize: isPortrait ? "2vw" : "2vh" }}
            >
              Projects:{" "}
            </span>
            <Space wrap>
              {[...Object.keys(collections), "empty"].map((collection, idx) => (
                <div
                  key={idx}
                  style={{
                    width: "4em",
                    height: "4em",
                    margin: "2em",
                  }}
                >
                  {collection !== "empty" ? (
                    <>
                      <Avatar
                        size={80}
                        src={require(`../../assets/images/${collection}.png`)}
                        onClick={() => {
                          isCollectionSelected(collection)
                            ? setSelectedCollections(
                                selectedCollections.filter(
                                  (e) => e !== collection
                                )
                              )
                            : setSelectedCollections([
                                collection,
                                ...selectedCollections,
                              ]);
                        }}
                      />
                      {isCollectionSelected(collection) && (
                        <Avatar
                          size={30}
                          style={{
                            position: "relative",
                            left: 55,
                            bottom: 35,
                          }}
                          src={require("../../assets/images/success.png")}
                        />
                      )}
                    </>
                  ) : (
                    <Avatar
                      size={80}
                      style={{
                        backgroundColor: "#fff",
                        color: "#ef3075",
                      }}
                      icon={<PlusOutlined />}
                    />
                  )}
                </div>
              ))}
            </Space>
          </div>
          <div style={styles.buttonWrapper}>
            <Button title={"Create QR"} htmlType="submit" />
          </div>
        </Formbox>
      </Col>
    </Row>
  );
};

export default Form;

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    flexDirection: "column",
    width: "100%",
  } as React.CSSProperties,
  title: {
    color: "#fff",
    fontWeight: 700,
    fontSize: "3em",
    position: "relative",
    textAlign: "center",
  } as React.CSSProperties,
  form: {
    display: "flex",
    flexDirection: "column",
    padding: "4em 5em",
    background:
      "linear-gradient(217deg, rgba(255,255,255,0.6), rgba(255,255,255,0.3) 70.71%)",
    border: "#fff solid 1px",
    borderRadius: 20,
  } as React.CSSProperties,
  label: {
    fontSize: "1.1em",
    fontWeight: 500,
    marginBottom: 5,
    display: "inline-block",
  } as React.CSSProperties,
  buttonWrapper: {
    display: "flex",
    marginTop: "10%",
    justifyContent: "center",
  } as React.CSSProperties,
};
