import React, { useState, useEffect } from "react";
import { Token, ListResponse, User } from "../types";
import api from "../api";

export const UserContext = React.createContext<useUserProps>(undefined as any);

export const UserProvider = ({
  address,
  initialUser,
  children,
}: {
  address: string;
  initialUser?: User;
  children: React.ReactElement | React.ReactElement[];
}) => {
  const userProps = useUser({ address, initialUser });
  return (
    <UserContext.Provider value={userProps}>
      <>{children}</>
    </UserContext.Provider>
  );
};

// this is data fetching without infinite loading
const useUser = ({
  address,
  initialUser,
}: {
  address: string;
  initialUser?: User;
}) => {
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ userAddress: address, ...initialUser });

  useEffect(() => {
    refresh();
  }, [address]);

  const refresh = async () => {
    setLoading(true);
    try {
      const result = await api.gravatar.getGravatar(address);
      setData(result);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  return { data, error, loading, refresh };
};

export type useUserProps = ReturnType<typeof useUser>;

export const UserTokenListContext = React.createContext<useUserListProps>(
  undefined as any
);

export const UserTokenListProvider = ({
  address,
  children,
}: {
  address: string;
  children: React.ReactElement | React.ReactElement[];
}) => {
  const userListProps = useUserTokenList({ address });
  return (
    <UserTokenListContext.Provider value={userListProps}>
      <>{children}</>
    </UserTokenListContext.Provider>
  );
};

// this is data fetching without infinite loading
const useUserTokenList = ({ address }: { address: string }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshing, setRefresh] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [hasMore, setHasMore] = useState(true);

  const next = async () => {
    // if there is no more cursor, don't even try to get one
    if (data.length > 0 && !cursor) {
      setHasMore(false);
      return;
    }

    setLoading(true);
    try {
      const response: ListResponse<Token> = await api.gravatar.getTokenList(
        address,
        "eth,polygon",
        cursor
      );

      if (!response.cursor) {
        setHasMore(false);
      }
      setCursor(response.cursor);
      const newList = data.concat(response.result);
      setData(newList);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const refresh = async () => {
    setRefresh(true);
    try {
      const response: ListResponse<Token> = await api.gravatar.getTokenList(
        address,
        "eth,polygon"
      );
      setCursor(response.cursor);
      setData(response?.result ? response.result : []);
      if (response.cursor) {
        setHasMore(true);
      }
    } catch (error) {
      setError(error);
    } finally {
      setRefresh(false);
    }
  };

  return { data, error, loading, next, refresh, refreshing, hasMore };
};

export type useUserListProps = ReturnType<typeof useUserTokenList>;
