import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import { MediaContext } from "../../contexts/MediaContext";
import Title from "../../components/Title";
import { VerifiedToken } from "../../types";
import api from "../../api";

interface AzukiProps {
  verifiedToken: VerifiedToken;
  onAnimationEnd: () => void;
}

const Azuki = (props: AzukiProps) => {
  const { verifiedToken, onAnimationEnd } = props;

  const [nftSize, setNftSize] = useState<number>(1008);
  const [sec, setSec] = useState(62);
  const mediaSpec = useContext(MediaContext);
  const [removedBgImg, setRemovedBgImg] = useState<string>();
  const [owner, setOwner] = useState("");
  const { height: screenY, width: screenX, isPortrait } = mediaSpec;

  useEffect(() => {
    // REDIRECT IF NO VERIFIED NFT
    if (!!!verifiedToken) {
      onAnimationEnd();
    }

    const { imageUrls, name, tokenId } = verifiedToken;

    // GET BG REMOVED IMAGE
    const formData = new FormData();
    formData.append("size", "auto");
    formData.append("image_url", imageUrls.imageUrl + "=s2048");
    removeBg(formData);
    setOwner(name);
  }, []);

  const removeBg = async (formData) => {
    try {
      const ImgArray = await api.removeBg.postRemoveBg(formData);
      let blob = new Blob([ImgArray], { type: "image/png" });
      const url = window.URL.createObjectURL(blob);
      setRemovedBgImg(url);
    } catch (error: any) {
      console.error(error);
    } finally {
    }
  };

  // EFFECT : NFT SIZE
  useEffect(() => {
    function handleNftSize() {
      isPortrait
        ? setNftSize(window.innerWidth * 1.0)
        : setNftSize(window.innerWidth * 0.5);
    }
    window.addEventListener("resize", handleNftSize);
    handleNftSize();
    return () => window.removeEventListener("resize", handleNftSize);
  }, []);

  // EFFECT : TIMER
  useEffect(() => {
    const timer = setInterval(() => {
      setSec(sec - 1);
    }, 1000);

    if (sec === 0) {
      onAnimationEnd();
    }

    return () => clearInterval(timer);
  }, [sec]);

  return (
    <motion.div
      style={styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.8, duration: 0.8 }}
      exit={{ opacity: 0 }}
    >
      <img
        src={require("../../assets/images/azuki_background.jpg")}
        style={{
          ...styles.bg,
          width: isPortrait ? "" : "100%",
          transform: isPortrait ? "scaleX(-1) translate(12%)" : "scaleX(-1)",
        }}
      />

      <motion.div>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: require(`../../assets/lottie/azuki_white.json`),
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          width={isPortrait ? "80%" : "60%"}
          height={isPortrait ? "60%" : "100%"}
          style={styles.lottie}
        />
      </motion.div>

      <div style={styles.wrapper}>
        <motion.img
          style={{
            ...styles.nft,
            y: screenY - nftSize,
            width: isPortrait ? "100%" : "50%",
          }}
          initial={{ x: screenX, y: screenY - nftSize, zIndex: 0 }}
          animate={{
            x: screenX - nftSize,
            y: screenY - nftSize,
            zIndex: 0,
          }}
          transition={{ duration: 2.0, delay: 1.1 }}
          src={removedBgImg}
          // src={require("../../assets/images/nfts/azuki1-removebg.png")}
        />

        <Title
          sec={sec}
          owner={owner}
          onAnimationEnd={onAnimationEnd}
          isPortrait={isPortrait}
        />
      </div>
    </motion.div>
  );
};

export default Azuki;

// STYLES
const styles = {
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    overflow: "hidden",
    position: "absolute",
  } as React.CSSProperties,
  bg: {
    position: "absolute",
    height: "100%",
  } as React.CSSProperties,
  lottie: {
    position: "absolute",
    top: -150,
    left: 0,
    zIndex: 4,
  } as React.CSSProperties,
  wrapper: {
    display: "flex",
  } as React.CSSProperties,
  nft: {
    position: "absolute",
  } as React.CSSProperties,
};
