import React from "react";
import Loading from "../components/Loading";
import Colors from "../constants/Colors";
import PullToRefresh from "react-simple-pull-to-refresh";
import ProgressiveImg from "../components/ProgressiveImg";
import { message } from "antd";

export default function ProfileView({
  children,
  ...props
}: {
  children: typeof React.Children;
}) {
  let subComponentList = Object.keys(ProfileView);
  let subComponents = subComponentList.map((key) => {
    return React.Children.map(children, (child) => {
      return child;
    });
  });

  return <>{subComponents.map((component) => component)}</>;
}

ProfileView.Container = (props) => {
  const { children } = props;
  return <div style={styles.container}>{children}</div>;
};

ProfileView.Header = (props) => {
  const { title = "Profile", center = false } = props;
  return (
    <div style={{ ...styles.header, textAlign: center ? "center" : "left" }}>
      {title}
    </div>
  );
};

export interface ProfileViewProfileProps {
  title: string;
  address: string;
  ens: string | undefined;
  balance: number;
  txCount: string | number;
  onAvatarClick: () => void;
  imageUrl: string | undefined;
  nftCount: number;
}

ProfileView.Profile = (props: ProfileViewProfileProps) => {
  const {
    title,
    address,
    imageUrl,
    balance,
    txCount,
    nftCount,
    onAvatarClick,
  } = props;

  return (
    <div style={styles.userInfo}>
      <div style={styles.avatarContainer}>
        <img
          src={imageUrl}
          alt=""
          style={{
            width: 96,
            height: 96,
            borderRadius: "100%",
          }}
        />
      </div>
      <div style={styles.infoContainer}>
        <div
          style={{
            width: 48,
            backgroundColor: Colors.yellow,
            fontSize: 10,
            color: "#444",
            padding: "0, 10px",
            fontWeight: "500",
            borderRadius: 10,
            textAlign: "center",
            marginBottom: 2,
          }}
        >
          #Kakao
        </div>

        <div style={styles.title}>{title}</div>
        <span style={styles.subtitle}>{address}</span>
        <div style={styles.profileMetadataContainer}>
          <div style={styles.profileMetadataColumn}>
            <div style={styles.profileMetadatatTitle}>{balance + " Ξ"}</div>
            <div style={styles.profileMetadataSubtitle}>{"ETH"}</div>
          </div>
          <div style={styles.profileMetadataColumn}>
            <div style={styles.profileMetadatatTitle}>{txCount}</div>
            <div style={styles.profileMetadataSubtitle}>{"Total TX"}</div>
          </div>
          <div style={styles.profileMetadataColumn}>
            <div style={styles.profileMetadatatTitle}>{nftCount}</div>
            <div style={styles.profileMetadataSubtitle}>{"Total NFTs"}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProfileView.TokenList = (props) => {
  let {
    data,
    hasMore,
    innerRef,
    loading,
    refresh,
    onClick,
    isHolderSelection,
    isTokenSelected,
  } = props;
  let startIndex = 0;
  let dataChunk = [];

  let chunkIdx = 0;
  for (let i = 0; i < data.length; i++) {
    if (dataChunk[chunkIdx]) {
      if (dataChunk[chunkIdx].length < 3) {
        dataChunk[chunkIdx].push(data[i]);
      } else {
        chunkIdx += 1;
        dataChunk[chunkIdx] = [data[i]];
      }
    } else {
      dataChunk.push([data[i]]);
    }
  }

  const _handleTokenSelect = (value) => {
    if (!!value.animationUrl) {
      message.warning("움직이는 NFT는 현재 지원되지 않습니다");
    } else {
      onClick(value);
    }
  };

  if (isHolderSelection) {
    return (
      <PullToRefresh
        onRefresh={async () => {
          startIndex = 0;
          chunkIdx = 0;
          refresh();
        }}
        refreshingContent={<Loading title={""} />}
        pullingContent={""}
      >
        <div style={{ ...styles.tokenListContainer }} id="token-grid">
          {dataChunk.map((data, index) => {
            return (
              <div style={styles.tokenListChunk} key={"dataChunk " + index}>
                {data.map((value, index) => {
                  return (
                    <div
                      style={{
                        ...styles.tokenListItem,
                        border: isTokenSelected(
                          value.contractAddress + value.tokenId
                        )
                          ? "3px solid #ef3075"
                          : "",
                      }}
                      key={value.contractAddress + value.tokenId + index}
                      onClick={() => _handleTokenSelect(value)}
                      ref={hasMore ? innerRef : null}
                    >
                      <ProfileView.Multimedia
                        value={value}
                      ></ProfileView.Multimedia>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </PullToRefresh>
    );
  }

  return (
    <PullToRefresh
      onRefresh={async () => {
        startIndex = 0;
        chunkIdx = 0;
        refresh();
      }}
      refreshingContent={<Loading title={""} />}
      pullingContent={""}
    >
      <div style={styles.tokenListContainer} id="token-grid">
        {dataChunk.map((data, index) => {
          return (
            <div style={styles.tokenListChunk} key={"dataChunk " + index}>
              {data.map((value, index) => {
                return (
                  <div
                    style={styles.tokenListItem}
                    key={value.contractAddress + value.tokenId + index}
                    onClick={() =>
                      onClick(value.chain, value.contractAddress, value.tokenId)
                    }
                    ref={hasMore ? innerRef : null}
                  >
                    <ProfileView.Multimedia value={value} />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </PullToRefresh>
  );
};

ProfileView.Multimedia = (props) => {
  const { value, token, children } = props;

  return value.animationUrl ? (
    <video
      src={value.animationUrl}
      style={{
        width: "100%",
        height: "100%",
        borderRadius: 20,
        objectFit: "cover",
      }}
      loop
      autoPlay
      muted
      preload="none"
    />
  ) : (
    <>
      <ProgressiveImg
        src={value.imageUrl}
        alt=""
        token={token}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 20,
          objectFit: "cover",
        }}
        isTokenSelected
      />
      {children}
    </>
  );
};

ProfileView.TokenListLoading = () => {
  return (
    <Loading
      containerStyle={{
        ...styles.tokenListContainer,
        paddingTop: 100,
      }}
    />
  );
};

const styles = {
  container: {
    width: "100%",
    maxWidth: 412,
    height: "100%",
    padding: "0 18px",
    display: "flex",
    flexDirection: "column",
  } as React.CSSProperties,
  header: {
    fontSize: 26,
    fontWeight: "500",
    paddingTop: 10,
    marginBottom: 15,
  },
  userInfo: {
    display: "flex",
    // padding: "20px 0",
    marginBottom: 25,
  },
  avatarContainer: {
    width: 96,
    marginRight: 10,
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
  } as React.CSSProperties,
  title: {
    fontWeight: "600",
    fontSize: 16,
    display: "flex",
    alignItems: "flex-end",
  } as React.CSSProperties,
  subtitle: {
    fontSize: 14,
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
    letterSpacing: -0.6,
    lineHeight: "1.5em",
    color: "#888",
    opacity: 0.8,
    marginBottom: 10,
  } as React.CSSProperties,
  profileMetadataContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  } as React.CSSProperties,
  profileMetadataColumn: {
    width: "33.3%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties,
  profileMetadatatTitle: {
    fontSize: 15,
    color: "#ddd",
    fontWeight: "500",
  },
  profileMetadataSubtitle: {
    fontSize: 12,
    color: "#acacac",
  },
  tokenListContainer: {
    width: "100%",
    maxHeight: 675,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "stretch",
    overflowY: "scroll",
    marginBottom: 10,
    flex: 1,
    flexDirection: "column",
  } as React.CSSProperties,
  tokenListChunk: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
    gap: "10px",
  } as React.CSSProperties,
  tokenListItem: {
    width: "28.5vw",
    maxWidth: "120px",
    borderRadius: 20,
    height: "28.5vw",
    maxHeight: "120px",
    display: "flex",
    justifySelf: "center",
  } as React.CSSProperties,
  tokenImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  } as React.CSSProperties,
  checkImg: {
    width: "20%",
    height: "20%",
    position: "relative",
    top: "-95%",
    right: "-75%",
  } as React.CSSProperties,
};
